<template>
  <div class="top">
<!--    这里注意设置轮播高度和图片高度一致-->
    <el-carousel style="width:1400px" :autoplay="isAutoplay" :interval="4000" height="600px">
      <el-carousel-item v-for="item in swiper" :key="item.img">
        <img v-if="item.type === 'image'" :src="item.img" alt="">
        <video @play="onPlay" @pause="onPause" @ended="onEnded" width="1400" height="600" controls :poster="item.img">
          <source :src="item.videoUrl" type="video/mp4">
          您的浏览器不支持视频标签。
        </video>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  export default {
    name: "Swiper",
    data(){
      return{
        isAutoplay: true,
        swiper:[
          {
            img: require('@/assets/swiper/swiper2.jpg'),
            href:'',
            type: 'image'
          },
          {
            img: require('@/assets/swiper/swiper3.jpg'),
            href:'',
            type: 'image'
          },
          {
            img: require('@/assets/swiper/swiper6.jpg'),
            href:'',
            type: 'video',
            videoUrl: require('@/assets/swiper/swiper7.mp4'),
          }
        ]
      }
    },
    mounted(){
    },
    methods: {

      onPlay(event) {
        // 视频开始播放时触发
        console.log('Video is playing');
        this.isAutoplay = false;
      },
      onPause(event) {
        // 视频暂停时触发
        console.log('Video is paused');
        this.isAutoplay = true;
      },
      onEnded(event) {
        // 视频播放完毕时触发
        console.log('Video has ended');
        this.isAutoplay = true;
      }
    
    }
  }
</script>

<style scoped>
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .top img{
    width: 100%;
    height: 100%;
  }
  .top{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
