<template>
  <div class="top">
    <div class="index">
      <div class="logo">
        <img src="~@/assets/logo.jpg" alt="">
        <p style="font-size: 28px;font-weight: 700">优益梦--优学益教，加分未来</p>
      </div>
      <div class="nav-box">
        <span v-for="item in nav" :key="item.name"
              :class="{active:isChecked(item)}" @click="navClick(item.path)">{{item.name}}</span>
        <div class="container">
          下载APP
          <div class="qr-code-container">
            <div class="qr-code-content">
              <div class="qr-code-item">
                <img class="qr-code" src="~@/assets/ios.jpg" alt="QR Code 1">
                <div style="color: #000;margin-top: 12px">iOS APP</div>
              </div>
              <div class="qr-code-item">
                <img class="qr-code" src="~@/assets/Android.jpg" alt="QR Code 2">
                <div style="color: #000;margin-top: 12px">Android APP</div>
              </div>
            </div>
            <div style="width: 100%;text-align: center;margin-top: -42px">快去扫码安装</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index",
    data(){
      return{
        nav:[
           {
            name:'首页',
            path:'/home'
          },
          {
            name:'优益优加书院',
            path:'/project'
          },
          {
            name:'小U答疑',
            path:'/answer'
          },
          {
            name:'关于我们',
            path:'/about'
          }
        ]
      }
    },
    methods:{
      isChecked(item){
        return !item.path.indexOf(this.$route.path)
      },
      navClick(path){
        this.$router.push(path)
      }
    },
  }
</script>

<style scoped>
  .top{
    display: flex;
    justify-content: center;
    background-color: #FAFAFA;
    width: 100%;
    z-index: 888;
  }
  .index{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    height: 112px;
  }
  .logo{
    display: flex;
    align-items: center;
  }
  .logo img{
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }
  .nav-box{
    width: 500px;
    display: flex;
    font-size: 16px;
    justify-content: space-evenly;
  }
  .nav-box span{
    /*不同浏览器的渐变效果*/
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
  .nav-box :hover{
    color: #3a8ee6;
    cursor:pointer;
  }
  .active{
    color: #3a8ee6;
  }

  .container {
      position: relative;
      display: inline-block;
    }

   .qr-code-container {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      /* border: 1px solid #ccc; */
      padding: 10px;
      z-index: 999;
      margin-left: -280px;
      
    }

    .qr-code-content {
      display: flex;
      margin-top: 22px;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 5px 0 5px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 22px;
      padding-bottom: 66px
    }

   .container:hover .qr-code-container {
      display: block;
    }

    .qr-code {
      width: 180px;
      margin: 22px 22px 12px;
      background: white;
    }

    .qr-code-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

</style>
