<template>
  <div class="top">
    <div class="bottom">
      <div class="content">
        <!-- <div class="index">
          <span v-for="item in nav" :key="item.name" @click="navClick(item.path)">{{item.name}}</span>
        </div> -->
        <div class="law">
          <span>版权所有： 成都优梦加科技有限公司</span>
          <img style="width:20px; height: 20px;margin-left: 44px;" src="../../../../src/assets/beian.jpg" alt="">
          <a style="margin-left: 4px; color: white" href="https://beian.mps.gov.cn/#/query/webSearch?code=51019002006282" rel="noreferrer" target="_blank">川公网安备51019002006282</a >
          <a style="margin-left: 44px; color: white" target="_blank" href="https://beian.miit.gov.cn">蜀ICP备2024061671号-1 </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Bottom",
    data(){
      return{
        nav:[
          {
            name:'首页',
            path:'/home'
          },
          {
            name:'关于我们',
            path:'/about'
          },
          {
            name:'小U答疑',
            path:'/project'
          },
          {
            name:'联系我们',
            path:'/contact'
          },
        ]
      }
    },
    methods:{
      navClick(path){
        this.$router.push(path)
      }
    }
  }
</script>

<style scoped>
  .top{
    width: 100%;
    height: 80px;
    background-color: #444444;
    display: flex;
    justify-content: center;
  }
  .bottom{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content{
    width: 60%;
  }
  .img{

  }
  .img p{
    margin: 0;
    color: #888888;
    font-size: 14px;
  }
  .img img{
    margin-top: 10px;
    width: 95px;
    height: 91px;
    border: 5px solid #3a8ee6;
  }
  .index{
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    border-bottom: 1px dashed #666666;
    padding-bottom: 30px;
    color: #cccccc;
  }
  .law{
    color: #999999;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .index :hover{
    font-weight: bold;
    color: #FFFFFF;
    cursor:pointer;
  }
</style>
